<template>
  <div id="wrapper">
    <div id="header">
        <div id="logo">
            <div style="display:inline-block; width:220px;">
                <img src="../../libs/images/laoRSP-logo.png" style="width:44%;"/>
            </div>
        </div>
    </div>
    <!-- end #header -->
    <div id="menu">

    </div>
    <!-- end #menu -->
    <div id="page">
        <div id="page-bgtop">
            <div id="page-bgbtm">
                <div id="content">
                    <div id="gallery-wrapper">
                        <div id="gallery"><img alt="" class="output" src="../../libs/images/03.jpg"/>
                        </div>
                        <!-- end -->
                    </div>
                    <div class="post">

                        <h2 class="title">ເລກລາງວັນ ຫວຍເພື່ອນກັນ ລ່າສຸດ</h2>
                        <p class="meta" id="result_tag">
                            <span class="date" id="lao_date">
                                <span class="date" id="lao_date">{{ ListDigit.work_date | formatDate }}</span>
                            </span>
                        </p>
                        <div style="clear: both;">&nbsp;</div>

                        <div class="rsptable">
                            <div class="row header">
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#ffffff;">ລາງວັນ</span>
                                </div>
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#ffffff;">ເລກ</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#303584;">ລາງວັນເລກ 5 ໂຕ</span>
                                </div>
                                <div class="cell">
                                    <span v-if="ListDigit.status == 1" class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ Digit5 }}
                                    </span>
                                    <span v-else class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ random_5 }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#303584;">ລາງວັນເລກ 4 ໂຕ</span>
                                </div>
                                <div class="cell">
                                    <span v-if="ListDigit.status == 1" class="entry" id="lao_4" style="font-size:25px;color:#303584;">
                                        {{ Digit4 }}
                                    </span>
                                    <span v-else class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ random_4 }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#303584;">ລາງວັນເລກ 3 ໂຕ</span>
                                </div>
                                <div class="cell">
                                    <span v-if="ListDigit.status == 1" class="entry" id="lao_3" style="font-size:25px;color:#303584;">
                                        {{ Digit3 }}
                                    </span>
                                    <span v-else class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ random_3 }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    <span class="entry" style="font-size:25px;color:#303584;">ລາງວັນເລກ 2 ໂຕ</span>
                                </div>
                                <div class="cell">
                                    <span v-if="ListDigit.status == 1" class="entry" id="lao_2" style="font-size:25px;color:#303584;">
                                        {{ Digit2 }}
                                    </span>
                                    <span v-else class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ random_2 }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell"><span class="entry" style="font-size:25px;color:#303584;">ລາງວັນເລກ 1 ໂຕ</span>
                                </div>
                                <div class="cell">
                                    <span v-if="ListDigit.status == 1" class="entry" id="lao_1" style="font-size:25px;color:#303584;">
                                        {{ Digit1 }}
                                    </span>
                                    <span v-else class="entry" id="lao_5" style="font-size:25px;color:#303584;">
                                        {{ random_1 }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="entry" style="font-size:16px;color:#303584;">
                            ລາງວັນເລກ 5 ໂຕ
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 40,000,000 ກີບ.<br/>
                            ລາງວັນເລກ 4 ໂຕ
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 6,000,000 ກີບ.<br/>
                            ລາງວັນເລກ 3 ໂຕ
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 500,000 ກີບ.<br/>
                            ລາງວັນເລກ 2 ໂຕ
                            ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 60,000 ກີບ.<br/>
                            ລາງວັນເລກ 1 ໂຕ ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 10,000 ກີບ.
                        </div>
                    </div>
                    <div class="last-result">
                        <form>
                            <h2 class="title"><a href="#">ກວດສອບຜົນການອອກຫວຍຍ້ອນຫຼັງ</a></h2>
                            <div class="rsptable">
                                <div class="row header">
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ງວດວັນທີ່</span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ເລກ 5 ໂຕ</span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ເລກ 4 ໂຕ</span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ເລກ 3 ໂຕ</span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ເລກ 2 ໂຕ</span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#ffffff;">ເລກ 1 ໂຕ</span>
                                    </div>
                                </div>
                                <div class="row" v-for="(Lists, index) in List" :key="index">
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                          {{ Lists.work_date | formatDate}}
                                        </span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                            {{ Lists.numberset | digit5}}
                                        </span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                            {{ Lists.numberset | digit4}}
                                        </span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                            {{ Lists.numberset | digit3}}
                                        </span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                            {{ Lists.numberset | digit2}}
                                        </span>
                                    </div>
                                    <div class="cell">
                                        <span class="entry" style="font-size:16px;color:#303584;">
                                            {{ Lists.numberset | digit1}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="media">
                        <div class="media-left">
                        </div>
                        <div class="media-body">
                            <h2 class="media-heading">ເອກະສານການສ້າງຕັ້ງ
                            </h2>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <img height="700" width="500" src="../../libs/images/certificate.png"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <!-- end #content -->
                <div id="sidebar">
                    <ul>
                        <li>
                            <h2> ການອອກລາງວັນອອນໄລ</h2>
                        </li>
                        <li>
                            <div id="search">
                                <form action="#" method="get">
                                    <div>
                                        <input id="search-text" name="s" type="text" value="">
                                        <input id="search-submit" type="submit" value="ເຂົ້າສູ່ລະບົບ">
                                    </div>
                                </form>
                            </div>
                            <div style="clear: both;">&nbsp;</div>
                        </li>
                        <h2>
                            ສົນໃຈເປັນຕົວແທນຈໍາໜ່າຍ
                            ເອກະສານປະກອບມີ
                        </h2>
                        <ul>
                            <li><a href="#">ໃບຢັ້ງຢືນທີ່ຢູ່</a></li>
                            <li><a href="#">ໃບຮັບປະກັນ</a></li>
                            <li><a href="#">ກ໋ອບປີ້ບັດປະຈຳຕົວ</a></li>
                            <li><a href="#">ແຜນທີ່ບ້ານ</a></li>
                            <li><a href="#">ຮູບເຮືອນ</a></li>
                        </ul>
                </ul></div>
                <!-- end #sidebar -->
                <div style="clear: both;">&nbsp;</div>
            </div>
        </div>
    </div>
    <!-- end #page -->
    <div id="footer">
      <p>&copy; ບໍລິສັດ ຫວຍເພື່ອນກັນ ຫວຍລາວ </p>
  </div>
</div>
</template>

<script>
import moment from "moment";
export default {
  name: "Home",
  data () {
      return {
        baseURL: "https://opensheet.vercel.app/1Xow1wf5LwHjkKgMbMb8h_9AJTjYXLwU6tDKnwSwmD2c/Now",
        ListDigit: [],
        Digit6: "",
        Digit5: "",
        Digit4: "",
        Digit3: "",
        Digit2: "",
        Digit1: "",
        random_6: "",
        random_5: "",
        random_4: "",
        random_3: "",
        random_2: "",
        random_1: "",
        setting:"",
        List: [],
      }
  },
  mounted() {
    this.Init_config();
    this.Init_Data();
    this.Render_setting();
    this.Init_Previous();
    setInterval(this.waiting_digit, 200);
    setInterval(this.updateClock, 200);
    setInterval(this.Render_setting, 5000);
  },
  methods: {
    Render_setting() {
        let annount_time = moment(this.setting.time_Announcement, 'HH:mm:ss').format("HH:mm")
        let close_time = moment(this.setting.close_time, 'HH:mm:ss').format("HH:mm")
        let current_time = moment(Date.now()).format("HH:mm")
        if(current_time == close_time){
            this.Init_config()
        }
        if(annount_time == current_time){
            this.Init_Data()
        }
    },
    updateClock() {
      String.prototype.replaceAt = function(index, replacement) {
        if (index >= this.length) {
            return this.valueOf();
        }
      
        return this.substring(0, index) + replacement + this.substring(index + 1);
      }
      let annount_time = moment(this.setting.time_Announcement, 'HH:mm:ss').format("HH:mm")
      let current_time = moment(Date.now()).format("HH:mm")
      var time = new Date()
      
      if(annount_time == current_time){
        let number_ = this.ListDigit.numberset + ""
        let digit6 = number_.charAt(0)
        let digit5 = number_.charAt(1)
        let digit4 = number_.charAt(2)
        let digit3 = number_.charAt(3)
        let digit2 = number_.charAt(4)
        let digit1 = number_.charAt(5)
        this.Digit6 = "XXXXXX";
        this.Digit5 = "XXXXX";
        this.Digit4 = "XXXX";
        this.Digit3 = "XXX";
        this.Digit2 = "XX";
        this.Digit1 = "X";
        if (time.getSeconds() >= 0 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(0, digit6)
        }
        if (time.getSeconds() >= 8 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(1, digit5)
          this.Digit5 = this.Digit5.replaceAt(0, digit5)
        }
        if (time.getSeconds() >= 16 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(2, digit4)
          this.Digit5 = this.Digit5.replaceAt(1, digit4)
          this.Digit4 = this.Digit4.replaceAt(0, digit4)
        }
        if (time.getSeconds() >= 24 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(3, digit3)
          this.Digit5 = this.Digit5.replaceAt(2, digit3)
          this.Digit4 = this.Digit4.replaceAt(1, digit3)
          this.Digit3 = this.Digit3.replaceAt(0, digit3)
        }
        if (time.getSeconds() >= 32 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(4, digit2)
          this.Digit5 = this.Digit5.replaceAt(3, digit2)
          this.Digit4 = this.Digit4.replaceAt(2, digit2)
          this.Digit3 = this.Digit3.replaceAt(1, digit2)
          this.Digit2 = this.Digit2.replaceAt(0, digit2)
        }
        if (time.getSeconds() >= 40 && time.getSeconds() <= 59) {
          this.Digit6 = this.Digit6.replaceAt(5, digit1)
          this.Digit5 = this.Digit5.replaceAt(4, digit1)
          this.Digit4 = this.Digit4.replaceAt(3, digit1)
          this.Digit3 = this.Digit3.replaceAt(2, digit1)
          this.Digit2 = this.Digit2.replaceAt(1, digit1)
          this.Digit1 = this.Digit1.replaceAt(0, digit1)
        }
        if (time.getSeconds() >= 0 && time.getSeconds() <= 7) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 8 && time.getSeconds() <= 15) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 16 && time.getSeconds() <= 23) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 24 && time.getSeconds() <= 31) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(4, Math.floor(Math.random() * 10)+'')
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(3, Math.floor(Math.random() * 10)+'')
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(2, Math.floor(Math.random() * 10)+'')
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(1, Math.floor(Math.random() * 10)+'')
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(0, Math.floor(Math.random() * 10)+'')
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
        if (time.getSeconds() >= 32 && time.getSeconds() <= 39) {
          //random6digit
           this.Digit6 = this.Digit6.replaceAt(5, Math.floor(Math.random() * 10)+'')
           //random5digit
           this.Digit5 = this.Digit5.replaceAt(4, Math.floor(Math.random() * 10)+'')
           //random4digit
           this.Digit4 = this.Digit4.replaceAt(3, Math.floor(Math.random() * 10)+'')
           //random3digit
           this.Digit3 = this.Digit3.replaceAt(2, Math.floor(Math.random() * 10)+'')
           //random2digit
           this.Digit2 = this.Digit2.replaceAt(1, Math.floor(Math.random() * 10)+'')
           //random1digit
           this.Digit1 = this.Digit1.replaceAt(0, Math.floor(Math.random() * 10)+'')
        }
      }
    },
    waiting_digit() {
      let password = "";
      const keys = {
        number: "0123456789",
      };
      const getKey = [
        function number() {
          return keys.number[Math.floor(Math.random() * keys.number.length)];
        },
      ];
      const length = 6;

      while (length > password.length) {
        let keyToAdd = getKey[Math.floor(Math.random() * getKey.length)];
        password += keyToAdd();
      }
      let num_random = password;

      this.random_6 = num_random+"";
      this.random_5 = this.random_6.slice(1);
      this.random_4 = this.random_6.slice(2);
      this.random_3 = this.random_6.slice(3);
      this.random_2 = this.random_6.slice(4);
      this.random_1 = this.random_6.slice(5);

    },
    async Init_config() {
      let Result = await this.axios.get(
        "https://api.laosrsp.com/api/v1/get_annountment/front"
      );
      this.setting = Result.data.res[0];
    },
    async Init_Data() {
      let Result = await this.axios.get("https://api.laosrsp.com/api/v1/get_show/front");
      this.ListDigit = Result.data.res[0];
      this.Digit6 = this.ListDigit.numberset+'';
      this.Digit5 = this.Digit6.slice(1);
      this.Digit4 = this.Digit6.slice(2);
      this.Digit3 = this.Digit6.slice(3);
      this.Digit2 = this.Digit6.slice(4);
      this.Digit1 = this.Digit6.slice(5);
    },
    async Init_Previous() {
      this.axios
        .get("https://api.laosrsp.com/api/v1/get_previous")
        .then((response) => {
          this.List = response.data.res;
        });
    },
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    digit5(value) {
      var string_digit = value+''
      return string_digit.slice(1) 
    },
    digit4(value) {
      var string_digit = value+''
      return string_digit.slice(2) 
    },
    digit3(value) {
      var string_digit = value+''
      return string_digit.slice(3) 
    },
    digit2(value) {
      var string_digit = value+''
      return string_digit.slice(4) 
    },
    digit1(value) {
      var string_digit = value+''
      return string_digit.slice(5) 
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
