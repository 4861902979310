<template>
  <router-view></router-view>
</template>

<script>
// css
require("../libs/style.css");
require("../libs/gallery.css");

// javascript
// require("../libs/jquery/jquery.gallerax-0.2")

export default {
    name: 'App',
    data () {
      return {}
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
